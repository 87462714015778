<template>
  <div class="useDocEntry">
    <div class="doc-content">
      <div class="equal-cent">
        <en-top-menu
          menu-name="下载中心"
          :need-back="false"
          type="white"
        >
        </en-top-menu>
        <div class="equal-main">
          <div class="tables">
          <template>
            <en-table
              :data="dataSource.records"
              @filter-sort-change="handleTableFilterChange"
              @cell-click="handleCouClick"
              height="100%">
              <en-table-column type="index" width="50"></en-table-column>
              <en-table-column title="文件名称" field="name" width="250"
              :filter-sort-config="{filterable:false, sortable:false}"
              :filter-render="{name: 'ElInput', attrs: {placeholder: '请输入文件名...'}}">
                <template v-slot="{ row}">
                <en-icon
                  :name="suffix(row).iconName"
                  :color="suffix(row).iconColor"
                  size="small"
                  ></en-icon>
                  {{ row.name }}
                </template>
              </en-table-column>
              <en-table-column title="文件大小" field="fileSize" width="150">
                <template v-slot="{ row, column }">
                  <div class="file-name">
                    <span>{{ row[column.field] }}</span>
                    <span v-if="row[column.field]">kb</span>
                  </div>
                </template>
              </en-table-column>
              <en-table-column title="完成时间" field="createTime" width="250"></en-table-column>
              <en-table-column title="处理状态" field="downloadStatus" width="250">
                <template v-slot="{ row, column }">
                  <div v-html="formatRow(row, column)"></div>
                </template>
              </en-table-column>
            </en-table>
          </template></div>
          <div class="bottom">
            <en-pagination :page-model="dataSource" @change="handlePageChanged"></en-pagination>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { request } from "en-js";
import { getFileUrl } from "@/tools/getFileUrl";
// import list from "../data/list";
import API from "@/api/downloadManager/list";
// import baseUrl from "../../../../baseUrl";

export default {
  data() {
    return {
      loading: false,
      dataSource: {
        pageNo: 1,
        pageSize: 10,
        totalPages: 0,
        total: 0,
        name: "",
        records: []
      }
    };
  },
  methods: {
    formatRow(row, column) {
      switch (row[column.field]) {
        case 1:
          return "<span>处理成功</span>";
        case 2:
          return "<span style=\"color: #F88528;\">正在处理</span>";
        case 3:
          return "<div>"
                      + "<span style=\"color: #F55C5E;vertical-align: middle;\">处理失败</span>"
                      + "<div class=\"ui-temp-notice\" style=\"display:inline-block;vertical-align: middle\">"
                          + "<div class=\"notice-main\">"
                              + "<i class=\"notice-icon\" style=\"font-size: 16px\"></i>"
                              + "<div class=\"notice-cent\">操作失败</div>"
                          + "</div>"
                      + "</div>"
                  + "<div>";
        default:
          return "<span>处理成功</span>";
      }
    },
    @request(true, "loading")
    async requestNewData() {
      // 接口请求
      const dataSource = this.dataSource;
      const params = {
        pageNo: dataSource.pageNo,
        name: dataSource.name,
        pageSize: dataSource.pageSize
      };
      params.commParams = dataSource.commParams;
      const res = await API.queryDataList(params);
      Object.assign(dataSource, res);
      dataSource.commParams = params.commParams;
    },
    /**
     * 表格筛选方法
     */
    handleTableFilterChange(data) {
      this.dataSource.name = data.data;
      this.requestNewData();
    },
    /**
     * 分页加载
     */
    handlePageChanged({ pageNo, pageSize }) {
      this.dataSource.pageNo = pageNo;
      this.dataSource.pageSize = pageSize;
      this.requestNewData();
    },
    /**
     * 点击
     */
    handleCouClick({ row, column }) {
      const { field } = column;

      if (field === "name" && row.url) {
        this.$store.getters.urlParam;
        const src = getFileUrl(row.url, "001", row.name);
        window.open(src);
      }
    },
    /**
     * @description: 获取后缀给相应icon
     * @param {type}
     * @return:
     */
    suffix(row) {
      /* eslint-disable */
      if (row.url.includes(".txt")) {
        return {
          iconName: "fujian-txt",
          iconColor: "#4794e2"
        };
      } else if (row.url.includes(".doc") || row.url.includes(".docx")) {
        return {
          iconName: "fujian-Word",
          iconColor: "#4794e2"
        };
      } else if (row.url.includes(".pdf")) {
        return {
          iconName: "fujian-pdf",
          iconColor: "#f76b6e"
        };
      } else if (row.url.includes(".xls") || row.url.includes(".xlsx")) {
        return {
          iconName: "fujian-excel",
          iconColor: "#57cc7d"
        };
      } else if (row.url.includes(".ppt") || row.url.includes(".pptx")) {
        return {
          iconName: "fujian-PPT",
          iconColor: "#f7c028"
        };
      } else if (row.url.includes(".gif") || row.url.includes(".png") || row.url.includes(".jpg") || row.url.includes(".bmp")) {
        return {
          iconName: "fujian-tupian",
          iconColor: "#f7bf27"
        };
      } else {
        return {
          iconName: "fujian-yasuobao",
          iconColor: "#4595de"
        };
      }
    }
  },
  mounted() {
    this.requestNewData();
  },
  watch: {
    $route(newVal, oldVal) {
      if (newVal !== oldVal) {
        // this.requestNewData(); // 重新调用加载函数
      }
    }
  }
  // mixins: [list]
};
</script>

<style lang="scss" scoped>
.useDocEntry {
  .tables {
    height: calc(100% - 60px);
  }

  background: #1d2431;
   .row {
    padding: 0 20px;
  }
  .doc-content {
    height: 100%;
  }
  .pull-left {
    float: left;
  }
  .pull-right {
    float: right;
  }
  .col-tit {
    font-size: 14px;
    color: #333;
    font-weight: bold;
  }
  .equal-side {
    width: 285px;
    border-radius: 5px;
    background: #fff;
    height: 100%;
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
    .equal-sidetopbar {
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      border-bottom: 1px solid #e8ecf2;

      .en-icon {
        color: #3e90fe;
      }
    }
  }
  .equal-topbar {
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    border-bottom: 1px solid #e8ecf2;
  }
  .equal-cent {
    border-radius: 5px;
    background: #fff;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
  }
  .equal-main {
    position: relative;
    height:  calc(100% - 50px);
    .en-icon {
      color: #3e90fe;
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;
    }
    .bottom{
      border-top: 1px solid #e8ecf2;
    }
  }
  & /deep/ .el-dropdown {
    height: 24px;
  }
  & /deep/ .top-menu-name {
    font-size: 14px !important;
  }
  & /deep/ .el-tree-node__label {
    text-align: left;
  }
  & /deep/ .el-dialog /deep/ .el-dialog__header {
    text-align: left;
  }
  & /deep/ .el-select {
    width: 100%;
  }
  & /deep/ .en-color-setting {
    float: left;
    margin-top: 4px;
  }
  & /deep/ .dialog-footer {
    .el-button {
      height: 32px;
      width: 72px;
      background: #3e90fe;
    }
  }
  .file-name{
    span{
      margin-left: 5px;
    }
  }
  /deep/ .el-tree{
    .el-tree-node__label{
      font-size: 12px;
    }
  }
}
</style>
